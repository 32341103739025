.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background-color: #f9fafb;

}
.join-box{

  height: 180px;
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  border-top: 0.6px solid rgba(209,213,219);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.join-box-header{
  width: 90%;
  height: 60px;
  margin-left: 2.5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.are-you-joining{
  font-size: 14px;
   font-family: "Roboto", serif;
   color: #000000;
   font-weight: bold;
}
.join-box-body{
  width: 95%;
  height: 60px;
  margin-left: 2.5%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.join-box-btn{
  width: 32%;
  height: 50px;
  border-radius: 4px;
   display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 
}